'use strict'

###*
 # @ngdoc object
 # @name home.controller:HomeCtrl

 # @description

###
class HomeCtrl
  ### @ngInject ###
  constructor: (
    $state
    $log
    $mdSidenav
    UiHelpers
    $rootScope
    $window
    _
    MyUser
  ) ->
    @ctrlName = 'HomeCtrl'
    @applicationName = 'Politie Antwerpen'
    @applicationIcon = 'devices_other'

    @navigationItems = [
      {
        state: 'home',
        icon: 'home',
        label: 'app.home'
      }
    ]

    @toggleSidenav = (target) ->
      $mdSidenav(target).toggle()

    @toggleMenu = ($mdOpenMenu, event) ->
      $mdOpenMenu(event)

    @showUserContextSelector = () ->
      # _.fromPairs (_.map $rootScope.user.userContexts, (v) -> [v.id, v.tenant.label])
      choices = {}
      for v in $rootScope.user.userContexts
        choices[v.id] = v.tenant.label
      choices

      selected = if $rootScope.user.activeUserContext then $rootScope.user.activeUserContext.id else null

      UiHelpers
        .promptSelection
          choices: choices
          selected: selected
          onSelect: (result) ->
            MyUser
              .one()
              .patch
                activeUserContext: result
              .then (response) ->
                $window.location.reload()

angular
  .module('home')
  .controller 'HomeCtrl', HomeCtrl
